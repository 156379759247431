body,
.card,
.card-header,
.hero {
  background-color: var(--skin-background-color-1);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color-1); }

.hero {
  border-bottom-color: var(--skin-background-color-1); }

.main-menu {
  background-color: var(--skin-banner-background-color-1); }

.banner-color {
  background-color: var(--skin-banner-background-color-1); }

.header-banner {
  background-color: var(--skin-banner-background-color-2);
  color: var(--skin-banner-text-color-1); }

.header-banner .close-button .close {
  background-color: var(--skin-primary-color-1); }

h1.header.page-title,
h1.header.page-title::before {
  background-color: var(--skin-heading-color-1);
  color: var(--skin-heading-color-1-invert); }

.product-tile {
  background-color: var(--skin-background-color-1);
  border: 10px solid var(--skin-border-color-1); }

.refinements ul li button {
  color: var(--skin-primary-color-1); }

.custom-select {
  background-color: var(--skin-selectbox-background-color-1);
  color: var(--skin-selectbox-text-color-1); }

a {
  color: var(--skin-primary-color-1); }

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary) {
  color: var(--skin-primary-color-1); }

.price {
  color: var(--skin-price-1); }

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: var(--skin-menu-color-1); }
  @media (min-width: 769px) {
    .navbar,
    .navbar .nav-item,
    .navbar .nav-item a,
    .navbar .nav-item.dropdown,
    .navbar .nav-item.dropdown a,
    .dropdown-menu,
    .dropdown-menu a {
      color: var(--skin-menu-color-1-invert); } }

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-selectbox-text-color-1); }

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-selectbox-background-color-1); }
  .navbar .nav-item.dropdown .dropdown-menu.show a,
  .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-selectbox-text-color-1); }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  background-color: var(--skin-menu-color-1-invert);
  color: var(--skin-menu-color-1) !important; }

.btn-primary {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  color: var(--skin-primary-color-invert-1); }

.btn-primary:hover {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1);
  background-color: var(--skin-primary-color-invert-1); }

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color);
  color: var(--skin-primary-color-invert-1);
  opacity: 0.5; }

.btn-outline-primary {
  color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

.btn-outline-primary:hover {
  color: var(--skin-primary-color-invert-1);
  background-color: var(--skin-primary-color-1);
  border-color: var(--skin-primary-color-1); }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--skin-header-font), sans-serif; }

a {
  color: var(--skin-link-color-1); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-white: #fff;
  --color-black: #000;
  --color-blue: #0056a2;
  --color-green: #088310;
  --color-primary: #00a1e0;
  --color-red: #e62144;
  --color-success: var(--color-green);
  --color-danger: var(--color-red);
  --color-light-blue: #7ed0ee;
  --color-grey1: #f8f8f8;
  --color-grey2: #f0eeee;
  --color-grey3: #d3d3d3;
  --color-grey4: #b7b7b7;
  --color-grey5: #7e7e7e;
  --color-grey6: #616161;
  --color-grey7: #333;
  --color-grey8: #222;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: var(--color-grey3);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color-1: var(--color-gray1);
  --skin-border-color-1: var(--color-white);
  --skin-main-text-color-1: var(--color-gray7);
  --skin-main-text-color-2: var(--color-gray7);
  --skin-menu-color-1: #444;
  --skin-menu-color-1-invert: var(--color-white);
  --skin-menu-color-2: #222;
  --skin-link-color-1: #444;
  --skin-link-color-2: var(--color-blue);
  --skin-primary-color-1: var(--color-primary);
  --skin-primary-color-2: var(--color-primary);
  --skin-primary-color-invert-1: var(--color-white);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color-1);
  --skin-banner-background-color-1: #444;
  --skin-banner-background-color-2: #222;
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-primary);
  --skin-heading-color-1-invert: var(--color-white);
  --skin-price-1: var(--color-gray7);
  --skin-header-font: 'Dosis';
  --skin-body-font: var(--font-family-sans-serif); }

:root {
  /** ==================================================================== **/
  /** Create Custom CSS Variables from "sfra bootstrap override" variables **/
  /** ==================================================================== **/
  --color-blue: #0056a2;
  --color-poolblue: #01AFEC;
  --color-bearblue: #0056a2;
  --color-blue-dark: #1d3557;
  --color-blue100: #f2fbfe;
  --color-blue200: #e5eef6;
  --color-blue300: #88b2d5;
  --color-blue400: #bfebfa;
  --color-cream: #fff5e4;
  --color-bc-black: #0f0f0f;
  --color-bc-lt-black: #1f1f1f;
  --color-glowworm: #dfe33c;
  --color-bc-red: #d41C55;
  --color-midnight: #201b50;
  --color-midnight100: #F4F4F6;
  --color-midnight200: #E9E8EE;
  --color-teal: #00807d;
  --color-teal100: #f5ffff;
  --color-teal200: #e0ffff;
  --color-teal300: #006c69;
  --color-teal400: #005855;
  --color-teal500: #00a6a4;
  --color-orange: #f28a21;
  --color-green: #088310;
  --color-gold: #f2b721;
  --color-primary: #0056a2;
  --color-secondary: #e62144;
  --color-red: #e62144;
  --color-red100: #ffecf0;
  --color-red200: #ffd8e0;
  --color-red300: #d00a2d;
  --color-red400: #a5001d;
  --color-light-blue: #7ed0ee;
  --color-light-green: #88c290;
  --color-dark-green: #b6c534;
  --color-purple: #662d91;
  --color-brown: #5B3B1B;
  --color-red-dark: #d00a2d;
  --color-success: #088310;
  --color-warning: #f28a21;
  --color-danger: #d00a2d;
  --color-white: #fff;
  --color-grey1: #f8f8f8;
  --color-grey2: #f0eeee;
  --color-grey3: #d3d3d3;
  --color-grey4: #b7b7b7;
  --color-grey5: #7e7e7e;
  --color-grey6: #616161;
  --color-grey7: #333;
  --color-grey8: #222;
  --color-black: #000;
  --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
  --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
  --color-light-gray: var(--color-grey1);
  --color-slightly-darker-gray: var(--color-grey4);
  --color-dark-gray: var(--color-grey6);
  --color-darker-gray: var(--color-grey7);
  --color-horizontal-rule-grey: var(--color-grey3);
  --color-product-number-grey: var(--color-grey3);
  --color-horizontal-border-grey: var(--color-grey4);
  --color-menu-link: var(--color-grey6);
  --color-close-menu-bg: var(--color-grey2);
  --color-link-color: var(--color-dark-gray);
  --color-hr-border-color: #e6e6e6;
  --util-box-shadow: 0 4px 10px rgba(0,0,0,.08);
  --util-box-shadow-active: 0 0 7px 0 rgb(119 119 119);
  --util-box-shadow-blue: 0 0 0 0.2rem rgba(0, 86, 162, 0.5);
  --util-box-shadow-glowworm: 0 0 0 0.2rem rgba(223, 227, 60, 0.5);
  /** ==================================================================== **/
  /** Define skin named variables here                                    **/
  /** ==================================================================== **/
  --skin-background-color: var(--color-white);
  --skin-background-color-1: var(--skin-background-color);
  --skin-border-color-1: var(--color-white);
  --skin-box-shadow: var(--util-box-shadow);
  --skin-box-shadow-active: var(--util-box-shadow-active);
  --skin-main-text-color: var(--color-blue-dark);
  --skin-main-text-color-1: var(--skin-main-text-color);
  --skin-main-text-color-2: var(--skin-main-text-color);
  --skin-primary-color-1: var(--color-blue);
  --skin-primary-color-invert-1: var(--color-blue-dark);
  --skin-primary-color: var(--color-blue);
  --skin-primary-color-invert: var(--color-blue-dark);
  --skin-secondary-color: var(--color-red);
  --skin-secondary-color-invert: var(--color-red300);
  --skin-selectbox-background-color-1: var(--color-white);
  --skin-selectbox-text-color-1: var(--skin-main-text-color);
  --skin-banner-background-color-1: var(--color-red);
  --skin-banner-background-color-2: var(--color-red);
  --skin-banner-text-color-1: var(--color-white);
  --skin-heading-color-1: var(--color-blue-dark);
  --skin-heading-color-1-invert: var(--color-blue);
  --skin-price-1: var(--color-grey7);
  --skin-header-font: SofiaPro, Arial, sans-serif;
  --skin-body-font: SofiaPro, Arial, sans-serif;
  --skin-promo-color: var(--color-light-green);
  --skin-bg-accent: var(--color-grey1);
  --skin-bg-dark: var(--color-bc-black);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-header-default-bg-color: var(--color-white);
  --skin-header-top-bg-color: var(--skin-header-default-bg-color);
  --skin-header-top-text-color: var(--skin-main-text-color);
  --skin-header-main-bg-color: var(--skin-header-default-bg-color);
  --skin-header-ribbon-bg-color: var(--color-blue);
  --skin-header-ribbon-text-color: var(--color-white);
  --skin-bc-header-default-bg-color: var(--color-bc-black);
  --skin-bc-header-top-bg-color: var(--skin-bc-header-default-bg-color);
  --skin-bc-header-top-text-color: var(--color-white);
  --skin-bc-header-main-bg-color: var(--skin-bc-header-default-bg-color);
  --skin-bc-header-ribbon-bg-color: var(--color-glowworm);
  --skin-bc-header-ribbon-text-color: var(--color-black);
  --skin-hb-header-default-bg-color: var(--color-white);
  --skin-hb-header-top-bg-color: var(--skin-hb-header-default-bg-color);
  --skin-hb-header-top-text-color: var(--skin-main-text-color);
  --skin-hb-header-main-bg-color: var(--skin-hb-header-default-bg-color);
  --skin-hb-header-ribbon-bg-color: var(--color-teal);
  --skin-hb-header-ribbon-text-color: var(--color-white);
  --skin-icon-color: var(--color-blue);
  --skin-bc-icon-color: var(--color-glowworm);
  --skin-hb-icon-color: var(--color-teal);
  --skin-menu-topnav-links-color: var(--skin-main-text-color);
  --skin-menu-topnav-links-color-hover: var(--color-blue);
  --skin-menu-nav-mobile-links-color: var(--color-blue);
  --skin-menu-nav-links-indicator-hover: var(--color-blue);
  --skin-menu-subnav-links-color: var(--skin-main-text-color);
  --skin-menu-subnav-bg-color: var(--color-white);
  --skin-bc-menu-topnav-links-color: var(--color-white);
  --skin-bc-menu-topnav-links-color-hover: var(--color-glowworm);
  --skin-bc-menu-nav-mobile-links-color: var(--color-white);
  --skin-bc-menu-nav-links-indicator-hover: var(--color-glowworm);
  --skin-bc-menu-subnav-links-color: var(--color-bc-black);
  --skin-bc-menu-subnav-bg-color: var(--color-bc-black);
  --skin-hb-menu-topnav-links-color: var(--skin-main-text-color);
  --skin-hb-menu-topnav-links-color-hover: var(--color-teal);
  --skin-hb-menu-nav-mobile-links-color: var(--skin-main-text-color);
  --skin-hb-menu-nav-links-indicator-hover: var(--color-teal);
  --skin-hb-menu-subnav-links-color: var(--skin-main-text-color);
  --skin-hb-menu-subnav-bg-color: var(--color-white);
  --skin-minicart-bg-desktop: transparent;
  --skin-minicart-bg-mobile: transparent;
  --skin-minicart-icon-color: var(--color-blue);
  --skin-minicart-icon-color-mobile: var(--color-blue);
  --skin-bc-minicart-bg-desktop: transparent;
  --skin-bc-minicart-bg-mobile: transparent;
  --skin-bc-minicart-icon-color: var(--color-glowworm);
  --skin-bc-minicart-icon-color-mobile: var(--color-glowworm);
  --skin-hb-minicart-bg-desktop: transparent;
  --skin-hb-minicart-bg-mobile: transparent;
  --skin-hb-minicart-icon-color: var(--color-teal);
  --skin-hb-minicart-icon-color-mobile: var(--color-teal);
  --skin-menu-color-1: var(--skin-menu-topnav-links-color);
  --skin-menu-color-1-invert: var(--skin-menu-topnav-links-color);
  --skin-menu-color-2: var(--skin-menu-topnav-links-color);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-footer-top-bg-color: var(--color-grey1);
  --skin-footer-main-bg-color: var(--color-white);
  --skin-footer-bottom-bg-color: var(--color-blue);
  --skin-bc-footer-top-bg-color: var(--color-grey1);
  --skin-bc-footer-main-bg-color: var(--color-bc-lt-black);
  --skin-bc-footer-bottom-bg-color: var(--color-bc-black);
  --skin-hb-footer-top-bg-color: var(--color-grey1);
  --skin-hb-footer-main-bg-color: var(--color-white);
  --skin-hb-footer-bottom-bg-color: var(--color-teal);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-icon-default-fill: var(--color-blue);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-link-color: var(--color-blue);
  --skin-link-color-hover: var(--color-blue);
  --skin-link-color-1: var(--skin-link-color);
  --skin-link-color-2: var(--skin-link-color-1);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-btn-font-size: 0.875rem;
  --skin-btn-padding: 0.75rem 0.75rem;
  --skin-btn-border-radius: 0.25rem;
  --skin-btn-sm-font-size: 0.75rem;
  --skin-btn-sm-padding: 0.5rem 0.5rem;
  --skin-btn-lg-font-size: 1rem;
  --skin-btn-lg-padding: 0.9375rem 0.875rem;
  --skin-btn-box-shadow: var(--util-box-shadow-blue);
  --skin-btn-primary-bg: var(--color-poolblue);
  --skin-btn-primary-txt: var(--color-white);
  --skin-btn-primary-bord: var(--color-poolblue);
  --skin-btn-primary-bg-hover: var(--color-bearblue);
  --skin-btn-primary-txt-hover: var(--color-white);
  --skin-btn-primary-bord-hover: var(--color-bearblue);
  --skin-btn-primary-bg-active: var(--color-bearblue);
  --skin-btn-primary-txt-active: var(--color-white);
  --skin-btn-primary-bord-active: var(--color-bearblue);
  --skin-btn-primary-bg-disabled: var(--color-grey4);
  --skin-btn-primary-txt-disabled: var(--color-white);
  --skin-btn-primary-bord-disabled: var(--color-grey4);
  --skin-btn-primary-ol-bg: var(--color-white);
  --skin-btn-primary-ol-txt: var(--color-blue);
  --skin-btn-primary-ol-bord: var(--color-blue);
  --skin-btn-primary-ol-bg-hover: var(--color-blue100);
  --skin-btn-primary-ol-txt-hover: var(--color-blue);
  --skin-btn-primary-ol-bord-hover: var(--color-blue);
  --skin-btn-primary-ol-bg-active: var(--color-blue200);
  --skin-btn-primary-ol-txt-active: var(--color-blue);
  --skin-btn-primary-ol-bord-active: var(--color-blue);
  --skin-btn-primary-ol-bg-disabled: var(--color-white);
  --skin-btn-primary-ol-txt-disabled: var(--color-grey4);
  --skin-btn-primary-ol-bord-disabled: var(--color-grey4);
  --skin-btn-primary-alt-bg: var(--color-poolblue);
  --skin-btn-primary-alt-txt: var(--color-white);
  --skin-btn-primary-alt-bord: var(--color-poolblue);
  --skin-btn-primary-alt-bg-hover: var(--color-white);
  --skin-btn-primary-alt-txt-hover: var(--color-poolblue);
  --skin-btn-primary-alt-bord-hover: var(--color-white);
  --skin-btn-primary-alt-bg-active: var(--color-white);
  --skin-btn-primary-alt-txt-active: var(--color-poolblue);
  --skin-btn-primary-alt-bord-active: var(--color-white);
  --skin-btn-primary-alt-bg-disabled: var(--skin-btn-primary-bg-disabled);
  --skin-btn-primary-alt-txt-disabled: var(--skin-btn-primary-txt-disabled);
  --skin-btn-primary-alt-bord-disabled: var(--skin-btn-primary-bord-disabled);
  --skin-btn-secondary-bg: var(--color-bearblue);
  --skin-btn-secondary-txt: var(--color-white);
  --skin-btn-secondary-bord: var(--color-bearblue);
  --skin-btn-secondary-bg-hover: var(--color-poolblue);
  --skin-btn-secondary-txt-hover: var(--color-white);
  --skin-btn-secondary-bord-hover: var(--color-poolblue);
  --skin-btn-secondary-bg-active: var(--color-poolblue);
  --skin-btn-secondary-txt-active: var(--color-white);
  --skin-btn-secondary-bord-active: var(--color-poolblue);
  --skin-btn-secondary-bg-disabled: var(--skin-btn-primary-bg-disabled);
  --skin-btn-secondary-txt-disabled: var(--skin-btn-primary-txt-disabled);
  --skin-btn-secondary-bord-disabled: var(--skin-btn-primary-bord-disabled);
  --skin-btn-secondary-ol-bg: var(--color-white);
  --skin-btn-secondary-ol-txt: var(--color-red400);
  --skin-btn-secondary-ol-bord: var(--color-red400);
  --skin-btn-secondary-ol-bg-hover: var(--color-red100);
  --skin-btn-secondary-ol-txt-hover: var(--color-red400);
  --skin-btn-secondary-ol-bord-hover: var(--color-red400);
  --skin-btn-secondary-ol-bg-active: var(--color-red200);
  --skin-btn-secondary-ol-txt-active: var(--color-red400);
  --skin-btn-secondary-ol-bord-active: var(--color-red400);
  --skin-btn-secondary-ol-bg-disabled: var(--skin-btn-primary-ol-bg-disabled);
  --skin-btn-secondary-ol-txt-disabled: var(--skin-btn-primary-ol-txt-disabled);
  --skin-btn-secondary-ol-bord-disabled: var(--skin-btn-primary-ol-bord-disabled);
  --skin-btn-secondary-alt-bg: var(--color-bearblue);
  --skin-btn-secondary-alt-txt: var(--color-white);
  --skin-btn-secondary-alt-bord: var(--color-bearblue);
  --skin-btn-secondary-alt-bg-hover: var(--color-white);
  --skin-btn-secondary-alt-txt-hover: var(--color-bearblue);
  --skin-btn-secondary-alt-bord-hover: var(--color-white);
  --skin-btn-secondary-alt-bg-active: var(--color-white);
  --skin-btn-secondary-alt-txt-active: var(--color-bearblue);
  --skin-btn-secondary-alt-bord-active: var(--color-white);
  --skin-btn-secondary-alt-bg-disabled: var(--skin-btn-primary-alt-bg-disabled);
  --skin-btn-secondary-alt-txt-disabled: var(--skin-btn-primary-alt-txt-disabled);
  --skin-btn-secondary-alt-bord-disabled: var(--skin-btn-primary-alt-bord-disabled);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-label-font-size: 0.875rem;
  --skin-label-color: var(--skin-main-text-color);
  --skin-input-font-size: 0.875rem;
  --skin-input-border-radius: 0.25rem;
  --skin-input-border-width: 1px;
  --skin-input-border-color: var(--color-grey4);
  --skin-input-border-color-filled: var(--color-grey5);
  --skin-input-text-color: var(--skin-main-text-color);
  --skin-input-bg-color: var(--color-white);
  --skin-input-placeholder-color: var(--color-grey4);
  --skin-input-focus-border-color: var(--color-blue);
  --skin-input-focus-text-color: var(--skin-input-text-color);
  --skin-input-disabled-border-color: var(--color-grey2);
  --skin-input-disabled-bg-color: var(--color-grey2);
  --skin-input-disabled-text-color: var(--color-grey5);
  --skin-input-disabled-placeholder-color: var(--color-grey5);
  --skin-fl-label-font-size: 0.875rem;
  --skin-fl-label-color: var(--color-grey5);
  --skin-fl-input-text-color: var(--skin-main-text-color);
  --skin-fl-input-placeholder-color: var(--color-grey5);
  --skin-input-helper-font-size: 0.75rem;
  --skin-input-helper-color: var(--color-grey4);
  --skin-check: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  --skin-check-dk: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  --skin-checkbox-border-radius: 0.25rem;
  --skin-checkbox-border-width: 2px;
  --skin-checkbox-border-color: var(--color-blue);
  --skin-checkbox-background: transparent;
  --skin-checkbox-active-border-color: var(--color-blue);
  --skin-checkbox-active-background: var(--color-blue);
  --skin-checkbox-disabled-label-color: var(--color-grey5);
  --skin-checkbox-disabled-border-color: var(--color-grey2);
  --skin-checkbox-disabled-background-color: var(--color-grey2);
  --skin-dot: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  --skin-dot-dk: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
  --skin-radio-border-width: 2px;
  --skin-radio-border-color: var(--color-blue);
  --skin-radio-background: transparent;
  --skin-radio-dot-size: 0.625rem;
  --skin-radio-active-border-color: var(--color-blue);
  --skin-radio-active-background: var(--color-blue);
  --skin-radio-disabled-label-color: var(--color-grey5);
  --skin-radio-disabled-border-color: var(--color-grey2);
  --skin-radio-disabled-background-color: var(--color-grey2);
  --skin-switch-border-width: 1px;
  --skin-switch-border-color: var(--color-blue);
  --skin-switch-btn-color: var(--color-blue);
  --skin-switch-background: var(--color-grey1);
  --skin-switch-active-border-color: var(--color-blue);
  --skin-switch-active-btn-color: var(--color-white);
  --skin-switch-active-background: var(--color-blue);
  --skin-switch-disabled-label-color: var(--color-grey5);
  --skin-switch-disabled-border-color: var(--color-grey3);
  --skin-switch-disabled-btn-color: var(--color-grey5);
  --skin-switch-disabled-background-color: var(--color-grey2);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-tile-text-color: var(--color-blue-dark);
  --skin-workshop-badge-color: var(--color-blue400);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-breadcrumb-color: var(--color-blue);
  /** ==================================================================== **/
  /** New Theme Variables **/
  /** ==================================================================== **/
  --link-color-switch-uk: #0056a2;
  --data-transfer-svg-color: #0056a2;
  --skin-box-background: #f8f8f8;
  --skin-box-border-color: var(--color-grey3);
  --skin-gift-card-bonus: var(--color-blue100);
  --password-strength-color1: var(--color-red300);
  --password-strength-color2: #F38A21;
  --password-strength-color3: #40AE49;
  --password-strength-color4: var(--color-green);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-progress-indicator-inactive: var(--color-grey3);
  --skin-progress-indicator-active: var(--color-blue);
  --skin-progress-indicator-check-svg: url('data:image/svg+xml;charset=utf-8,<svg stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 13L9 17L19 7" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  /** ==================================================================== **/
  /** Age Gate Theme Variable **/
  /** ==================================================================== **/
  --skin-background-color-agegate: #0056a2;
  --skin-text-color-agegate: var(--color-white); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
body,
.hero {
  background-color: var(--skin-background-color);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color); }

.highlight {
  background-color: var(--skin-bg-accent); }

.product-tile {
  border: 0;
  background-color: transparent; }

.price {
  color: var(--skin-tile-text-color); }
  .price .starting,
  .price .range,
  .price .sales {
    font-weight: normal; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
body.bearcave {
  color: var(--color-black);
  /** ==================================================================== **/
  /** New Theme Variables - Bearcave Overwrites **/
  /** ==================================================================== **/
  --skin-link-color: var(--color-midnight);
  --link-color-switch-uk: #fff;
  --data-transfer-svg-color: #dfe33c;
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-btn-box-shadow: var(--util-box-shadow-glowworm);
  --skin-btn-primary-bg: var(--color-red);
  --skin-btn-primary-txt: var(--color-white);
  --skin-btn-primary-bord: var(--color-red);
  --skin-btn-primary-bg-hover: var(--color-red300);
  --skin-btn-primary-txt-hover: var(--color-white);
  --skin-btn-primary-bord-hover: var(--color-red300);
  --skin-btn-primary-bg-active: var(--color-red400);
  --skin-btn-primary-txt-active: var(--color-white);
  --skin-btn-primary-bord-active: var(--color-red400);
  --skin-btn-primary-bg-disabled: var(--color-grey4);
  --skin-btn-primary-txt-disabled: var(--color-white);
  --skin-btn-primary-bord-disabled: var(--color-grey4);
  --skin-btn-primary-ol-bg: var(--color-bc-black);
  --skin-btn-primary-ol-txt: var(--color-glowworm);
  --skin-btn-primary-ol-bord: var(--color-glowworm);
  --skin-btn-primary-ol-bg-hover: var(--color-black);
  --skin-btn-primary-ol-txt-hover: var(--color-glowworm);
  --skin-btn-primary-ol-bord-hover: var(--color-glowworm);
  --skin-btn-primary-ol-bg-active: var(--color-grey7);
  --skin-btn-primary-ol-txt-active: var(--color-glowworm);
  --skin-btn-primary-ol-bord-active: var(--color-glowworm);
  --skin-btn-primary-ol-bg-disabled: var(--color-grey4);
  --skin-btn-primary-ol-txt-disabled: var(--color-white);
  --skin-btn-primary-ol-bord-disabled: var(--color-grey4);
  --skin-btn-secondary-bg: var(--color-white);
  --skin-btn-secondary-txt: var(--color-midnight);
  --skin-btn-secondary-bord: var(--color-white);
  --skin-btn-secondary-bg-hover: var(--color-midnight100);
  --skin-btn-secondary-txt-hover: var(--color-midnight);
  --skin-btn-secondary-bord-hover: var(--color-white);
  --skin-btn-secondary-bg-active: var(--color-midnight200);
  --skin-btn-secondary-txt-active: var(--color-midnight);
  --skin-btn-secondary-bord-active: var(--color-white);
  --skin-btn-secondary-bg-disabled: var(--skin-btn-primary-bg-disabled);
  --skin-btn-secondary-txt-disabled: var(--skin-btn-primary-txt-disabled);
  --skin-btn-secondary-bord-disabled: var(--skin-btn-primary-bord-disabled);
  --skin-btn-secondary-ol-bg: var(--color-white);
  --skin-btn-secondary-ol-txt: var(--color-midnight);
  --skin-btn-secondary-ol-bord: var(--color-midnight);
  --skin-btn-secondary-ol-bg-hover: var(--color-midnight100);
  --skin-btn-secondary-ol-txt-hover: var(--color-midnight);
  --skin-btn-secondary-ol-bord-hover: var(--color-midnight);
  --skin-btn-secondary-ol-bg-active: var(--color-midnight200);
  --skin-btn-secondary-ol-txt-active: var(--color-midnight);
  --skin-btn-secondary-ol-bord-active: var(--color-midnight);
  --skin-btn-secondary-ol-bg-disabled: var(--skin-btn-primary-ol-bg-disabled);
  --skin-btn-secondary-ol-txt-disabled: var(--skin-btn-primary-ol-txt-disabled);
  --skin-btn-secondary-ol-bord-disabled: var(--skin-btn-primary-ol-bord-disabled);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-checkbox-border-color: var(--color-red);
  --skin-checkbox-active-border-color: var(--color-red);
  --skin-checkbox-active-background: var(--color-red);
  --skin-radio-border-color: var(--color-red);
  --skin-radio-active-border-color: var(--color-red);
  --skin-radio-active-background: var(--color-red);
  --skin-switch-border-color: var(--color-red);
  --skin-switch-btn-color: var(--color-red);
  --skin-switch-background: var(--color-grey1);
  --skin-switch-active-border-color: var(--color-red);
  --skin-switch-active-btn-color: var(--color-white);
  --skin-switch-active-background: var(--color-red);
  --skin-bc-menu-subnav-links-color: var(--color-white);
  /** ==================================================================== **/
  /** Age Gate Theme Variable **/
  /** ==================================================================== **/
  --skin-background-color-agegate: var(--color-bc-black);
  --skin-text-color-agegate: var(--color-white);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-breadcrumb-color: var(--color-black); }
  body.bearcave.dark-theme,
  body.bearcave .dark-theme,
  body.bearcave .dark-bg {
    color: var(--color-white);
    --skin-link-color: var(--color-glowworm);
    --skin-tile-text-color: var(--color-white);
    --skin-label-color: var(--color-white);
    --skin-breadcrumb-color: var(--color-white); }
  body.bearcave.dark-theme,
  body.bearcave .dark-theme {
    background-image: url("../../images/concrete-texture-bg.png");
    background-repeat: repeat; }
  body.bearcave .light-bg {
    color: var(--skin-main-text-color); }
  body.bearcave .dark-theme .btn-primary,
  body.bearcave .dark-theme .btn-primary:hover,
  body.bearcave .dark-theme .btn-primary:active,
  body.bearcave .dark-theme .btn-primary:focus,
  body.bearcave .dark-theme .btn-primary:disabled,
  body.bearcave .dark-bg .btn-primary,
  body.bearcave .dark-bg .btn-primary:hover,
  body.bearcave .dark-bg .btn-primary:active,
  body.bearcave .dark-bg .btn-primary:focus,
  body.bearcave .dark-bg .btn-primary:disabled,
  body.bearcave button.btn.btn-primary.continue,
  body.bearcave button.btn.btn-primary.go-back-btn,
  body.bearcave button.add-to-cart-tile,
  body.bearcave .minicart .btn-wrapper .checkout-btn {
    text-shadow: none !important; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
body.heartbox {
  /** ==================================================================== **/
  /** New Theme Variables - HeartBox Overwrites **/
  /** ==================================================================== **/
  --util-box-shadow-blue: 0 0 0 0.2rem rgba(0, 86, 162, 0.5);
  --util-box-shadow-glowworm: 0 0 0 0.2rem rgba(223, 227, 60, 0.5);
  --link-color-switch-uk: #1d3557;
  --data-transfer-svg-color: #00807d;
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-btn-primary-bg: var(--color-teal);
  --skin-btn-primary-txt: var(--color-white);
  --skin-btn-primary-bord: var(--color-teal);
  --skin-btn-primary-bg-hover: var(--color-teal300);
  --skin-btn-primary-txt-hover: var(--color-white);
  --skin-btn-primary-bord-hover: var(--color-teal300);
  --skin-btn-primary-bg-active: var(--color-teal400);
  --skin-btn-primary-txt-active: var(--color-white);
  --skin-btn-primary-bord-active: var(--color-teal400);
  --skin-btn-primary-bg-disabled: var(--color-grey4);
  --skin-btn-primary-txt-disabled: var(--color-white);
  --skin-btn-primary-bord-disabled: var(--color-grey4);
  --skin-btn-primary-ol-bg: var(--color-white);
  --skin-btn-primary-ol-txt: var(--color-teal);
  --skin-btn-primary-ol-bord: var(--color-teal);
  --skin-btn-primary-ol-bg-hover: var(--color-teal100);
  --skin-btn-primary-ol-txt-hover: var(--color-teal);
  --skin-btn-primary-ol-bord-hover: var(--color-teal);
  --skin-btn-primary-ol-bg-active: var(--color-teal200);
  --skin-btn-primary-ol-txt-active: var(--color-teal);
  --skin-btn-primary-ol-bord-active: var(--color-teal);
  --skin-btn-primary-ol-bg-disabled: var(--color-white);
  --skin-btn-primary-ol-txt-disabled: var(--color-grey4);
  --skin-btn-primary-ol-bord-disabled: var(--color-grey4);
  --skin-btn-secondary-bg: var(--color-white);
  --skin-btn-secondary-txt: var(--color-teal);
  --skin-btn-secondary-bord: var(--color-white);
  --skin-btn-secondary-bg-hover: var(--color-teal100);
  --skin-btn-secondary-txt-hover: var(--color-teal);
  --skin-btn-secondary-bord-hover: var(--color-teal100);
  --skin-btn-secondary-bg-active: var(--color-teal200);
  --skin-btn-secondary-txt-active: var(--color-teal);
  --skin-btn-secondary-bord-active: var(--color-teal200);
  --skin-btn-secondary-bg-disabled: var(--skin-btn-primary-bg-disabled);
  --skin-btn-secondary-txt-disabled: var(--skin-btn-primary-txt-disabled);
  --skin-btn-secondary-bord-disabled: var(--skin-btn-primary-bord-disabled);
  --skin-btn-secondary-ol-bg: var(--color-white);
  --skin-btn-secondary-ol-txt: var(--color-red400);
  --skin-btn-secondary-ol-bord: var(--color-red400);
  --skin-btn-secondary-ol-bg-hover: var(--color-red100);
  --skin-btn-secondary-ol-txt-hover: var(--color-red400);
  --skin-btn-secondary-ol-bord-hover: var(--color-red400);
  --skin-btn-secondary-ol-bg-active: var(--color-red200);
  --skin-btn-secondary-ol-txt-active: var(--color-red400);
  --skin-btn-secondary-ol-bord-active: var(--color-red400);
  --skin-btn-secondary-ol-bg-disabled: var(--skin-btn-primary-ol-bg-disabled);
  --skin-btn-secondary-ol-txt-disabled: var(--skin-btn-primary-ol-txt-disabled);
  --skin-btn-secondary-ol-bord-disabled: var(--skin-btn-primary-ol-bord-disabled);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-checkbox-border-color: var(--color-teal);
  --skin-checkbox-active-border-color: var(--color-teal);
  --skin-checkbox-active-background: var(--color-teal);
  --skin-radio-border-color: var(--color-teal);
  --skin-radio-active-border-color: var(--color-teal);
  --skin-radio-active-background: var(--color-teal);
  --skin-switch-border-color: var(--color-teal);
  --skin-switch-btn-color: var(--color-teal);
  --skin-switch-background: var(--color-grey1);
  --skin-switch-active-border-color: var(--color-teal);
  --skin-switch-active-btn-color: var(--color-white);
  --skin-switch-active-background: var(--color-teal);
  /** ==================================================================== **/
  /** ==================================================================== **/
  --skin-breadcrumb-color: var(--color-teal);
  /** ==================================================================== **/
  /** Age Gate Theme Variable **/
  /** ==================================================================== **/
  --skin-background-color-agegate: var(--color-white);
  --skin-text-color-agegate: #1d3557; }

h1,
.h1,
.heading1 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem; }
  @media (min-width: 769px) {
    h1,
    .h1,
    .heading1 {
      font-size: 3rem;
      line-height: 3.5rem; } }
  h1.normal,
  .h1.normal,
  .heading1.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }
  h1.header.page-title, h1.header.page-title::before,
  .h1.header.page-title,
  .h1.header.page-title::before,
  .heading1.header.page-title,
  .heading1.header.page-title::before {
    background-color: transparent;
    color: var(--skin-main-text-color); }
    .homepage h1.header.page-title, .homepage h1.header.page-title::before, .homepage
    .h1.header.page-title, .homepage
    .h1.header.page-title::before, .homepage
    .heading1.header.page-title, .homepage
    .heading1.header.page-title::before {
      background-color: var(--skin-heading-color-1);
      color: var(--skin-heading-color-1-invert); }

h2,
.h2,
.heading2 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.25rem; }
  @media (min-width: 769px) {
    h2,
    .h2,
    .heading2 {
      font-size: 2.5rem;
      line-height: 3rem; } }
  h2.normal,
  .h2.normal,
  .heading2.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }

h3,
.h3,
.heading3 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem; }
  @media (min-width: 769px) {
    h3,
    .h3,
    .heading3 {
      font-size: 2rem;
      line-height: 2.5rem; } }
  h3.normal,
  .h3.normal,
  .heading3.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }

h4,
.h4,
.heading4 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem; }
  @media (min-width: 769px) {
    h4,
    .h4,
    .heading4 {
      font-size: 1.5rem;
      line-height: 2rem; } }
  h4.normal,
  .h4.normal,
  .heading4.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }

h5,
.h5,
.heading5 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem; }
  @media (min-width: 769px) {
    h5,
    .h5,
    .heading5 {
      font-size: 1.25rem;
      line-height: 1.75rem; } }
  h5.normal,
  .h5.normal,
  .heading5.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }

h6,
.h6,
.heading6 {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem; }
  @media (min-width: 769px) {
    h6,
    .h6,
    .heading6 {
      font-size: 1rem;
      line-height: 1.5rem; } }
  h6.normal,
  .h6.normal,
  .heading6.normal {
    font-family: SofiaPro, Arial, sans-serif;
    font-style: normal;
    font-weight: normal; }

p {
  font-family: SofiaPro, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem; }
  p.bold {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600; }
  p.small {
    font-size: 0.875rem;
    line-height: 1.3125rem; }

a {
  color: var(--skin-link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--skin-link-color-hover); }
  a:focus {
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }
  a:focus-visible {
    -webkit-box-shadow: none;
            box-shadow: none;
    outline: -webkit-focus-ring-color auto 1px; }

b {
  font-family: SofiaProBold, Arial, sans-serif;
  font-weight: 600; }

.inheritFont {
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit; }

button:focus, button:active:focus, button.active:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.customize-button:focus,
.customize-button:active:focus,
.customize-button.active:focus,
.add-to-cart-tile:focus,
.add-to-cart-tile:active:focus,
.add-to-cart-tile.active:focus,
.btn-text:focus,
.btn-text:active:focus,
.btn-text.active:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none; }

button:focus-visible,
.btn:focus-visible,
.btn-primary:focus-visible,
.btn-outline-primary:focus-visible,
.btn-outline-secondary:focus-visible,
.customize-button:focus-visible,
.add-to-cart-tile:focus-visible,
.btn-text:focus-visible {
  outline: 0;
  -webkit-box-shadow: var(--skin-btn-box-shadow);
          box-shadow: var(--skin-btn-box-shadow); }

.btn {
  font-size: var(--skin-btn-font-size);
  padding: var(--skin-btn-padding);
  border-radius: var(--skin-btn-border-radius);
  text-transform: none;
  text-decoration: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1; }
  .btn.small {
    font-size: var(--skin-btn-sm-font-size);
    padding: var(--skin-btn-sm-padding); }
  .btn.large {
    font-size: var(--skin-btn-lg-font-size);
    padding: var(--skin-btn-lg-padding); }
  .btn .svg-icon {
    margin: -0.3125rem 0; }

.btn-primary {
  background-color: var(--skin-btn-primary-bg);
  border-color: var(--skin-btn-primary-bord);
  color: var(--skin-btn-primary-txt);
  text-shadow: 1px 1px #0079D2, -1px -1px #0079D2, -1px 1px #0079D2, 1px -1px #0079D2; }
  .btn-primary:hover {
    background-color: var(--skin-btn-primary-bg-hover);
    border-color: var(--skin-btn-primary-bord-hover);
    color: var(--skin-btn-primary-txt-hover);
    text-shadow: none; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-primary-bg-active);
    border-color: var(--skin-btn-primary-bord-active);
    color: var(--skin-btn-primary-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary:disabled, .btn-primary:disabled:hover {
    background-color: var(--skin-btn-primary-bg-disabled);
    border-color: var(--skin-btn-primary-bord-disabled);
    color: var(--skin-btn-primary-txt-disabled);
    opacity: 1; }
  .btn-primary .svg-icon {
    color: inherit; }

.btn-outline-primary {
  background-color: var(--skin-btn-primary-ol-bg);
  border-color: var(--skin-btn-primary-ol-bord);
  color: var(--skin-btn-primary-ol-txt); }
  .btn-outline-primary:hover {
    background-color: var(--skin-btn-primary-ol-bg-hover);
    border-color: var(--skin-btn-primary-ol-bord-hover);
    color: var(--skin-btn-primary-ol-txt-hover); }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-primary-ol-bg-active);
    border-color: var(--skin-btn-primary-ol-bord-active);
    color: var(--skin-btn-primary-ol-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-outline-primary.disabled, .btn-outline-primary.disabled:hover, .btn-outline-primary:disabled, .btn-outline-primary:disabled:hover {
    background-color: var(--skin-btn-primary-ol-bg-disabled);
    border-color: var(--skin-btn-primary-ol-bord-disabled);
    color: var(--skin-btn-primary-ol-txt-disabled);
    opacity: 1; }
  .btn-outline-primary .svg-icon {
    color: inherit; }

.btn-alt-primary {
  background-color: var(--skin-btn-primary-alt-bg);
  border-color: var(--skin-btn-primary-alt-bord);
  color: var(--skin-btn-primary-alt-txt); }
  .btn-alt-primary:hover {
    background-color: var(--skin-btn-primary-alt-bg-hover);
    border-color: var(--skin-btn-primary-alt-bord-hover);
    color: var(--skin-btn-primary-alt-txt-hover); }
  .btn-alt-primary:not(:disabled):not(.disabled):active, .btn-alt-primary:not(:disabled):not(.disabled).active, .btn-alt-primary:not(:disabled):not(.disabled):active:focus, .btn-alt-primary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-primary-alt-bg-active);
    border-color: var(--skin-btn-primary-alt-bord-active);
    color: var(--skin-btn-primary-alt-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-alt-primary.disabled, .btn-alt-primary.disabled:hover, .btn-alt-primary:disabled, .btn-alt-primary:disabled:hover {
    background-color: var(--skin-btn-primary-alt-bg-disabled);
    border-color: var(--skin-btn-primary-alt-bord-disabled);
    color: var(--skin-btn-primary-alt-txt-disabled);
    opacity: 1; }
  .btn-alt-primary .svg-icon {
    color: inherit; }

.btn-secondary {
  background-color: var(--skin-btn-secondary-bg);
  border-color: var(--skin-btn-secondary-bord);
  color: var(--skin-btn-secondary-txt); }
  .btn-secondary:hover {
    background-color: var(--skin-btn-secondary-bg-hover);
    border-color: var(--skin-btn-secondary-bord-hover);
    color: var(--skin-btn-secondary-txt-hover); }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-secondary-bg-active);
    border-color: var(--skin-btn-secondary-bord-active);
    color: var(--skin-btn-secondary-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-secondary.disabled, .btn-secondary.disabled:hover, .btn-secondary:disabled, .btn-secondary:disabled:hover {
    background-color: var(--skin-btn-secondary-bg-disabled);
    border-color: var(--skin-btn-secondary-bord-disabled);
    color: var(--skin-btn-secondary-txt-disabled);
    opacity: 1; }
  .btn-secondary .svg-icon {
    color: inherit; }

.btn-outline-secondary {
  background-color: var(--skin-btn-secondary-ol-bg);
  border-color: var(--skin-btn-secondary-ol-bord);
  color: var(--skin-btn-secondary-ol-txt); }
  .btn-outline-secondary:hover {
    background-color: var(--skin-btn-secondary-ol-bg-hover);
    border-color: var(--skin-btn-secondary-ol-bord-hover);
    color: var(--skin-btn-secondary-ol-txt-hover); }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-secondary-ol-bg-active);
    border-color: var(--skin-btn-secondary-ol-bord-active);
    color: var(--skin-btn-secondary-ol-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-outline-secondary.disabled, .btn-outline-secondary.disabled:hover, .btn-outline-secondary:disabled, .btn-outline-secondary:disabled:hover {
    background-color: var(--skin-btn-secondary-ol-bg-disabled);
    border-color: var(--skin-btn-secondary-ol-bord-disabled);
    color: var(--skin-btn-secondary-ol-txt-disabled);
    opacity: 1; }
  .btn-outline-secondary .svg-icon {
    color: inherit; }

.btn-alt-secondary {
  background-color: var(--skin-btn-secondary-alt-bg);
  border-color: var(--skin-btn-secondary-alt-bord);
  color: var(--skin-btn-secondary-alt-txt); }
  .btn-alt-secondary:hover {
    background-color: var(--skin-btn-secondary-alt-bg-hover);
    border-color: var(--skin-btn-secondary-alt-bord-hover);
    color: var(--skin-btn-secondary-alt-txt-hover); }
  .btn-alt-secondary:not(:disabled):not(.disabled):active, .btn-alt-secondary:not(:disabled):not(.disabled).active, .btn-alt-secondary:not(:disabled):not(.disabled):active:focus, .btn-alt-secondary:not(:disabled):not(.disabled).active:focus {
    background-color: var(--skin-btn-secondary-alt-bg-active);
    border-color: var(--skin-btn-secondary-alt-bord-active);
    color: var(--skin-btn-secondary-alt-txt-active);
    -webkit-box-shadow: none;
            box-shadow: none; }
  .btn-alt-secondary.disabled, .btn-alt-secondary.disabled:hover, .btn-alt-secondary:disabled, .btn-alt-secondary:disabled:hover {
    background-color: var(--skin-btn-secondary-alt-bg-disabled);
    border-color: var(--skin-btn-secondary-alt-bord-disabled);
    color: var(--skin-btn-secondary-alt-txt-disabled);
    opacity: 1; }
  .btn-alt-secondary .svg-icon {
    color: inherit; }

.btn-text {
  background-color: transparent;
  border-color: transparent;
  color: var(--skin-link-color);
  padding: 0.375rem 0;
  text-decoration: underline;
  font-size: 1rem; }
  .btn-text:hover {
    color: var(--skin-link-color-hover); }
  .btn-text .svg-icon {
    color: inherit; }

.btn-disabled {
  background-color: var(--skin-btn-primary-bord-disabled);
  border-color: var(--skin-btn-primary-bord-disabled);
  color: var(--skin-btn-primary-txt); }
  .btn-disabled:hover {
    color: var(--skin-btn-primary-txt); }
  .btn-disabled.disabled {
    pointer-events: none; }

.btn-group-toggle .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-group-toggle .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-group-toggle .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-group-toggle .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
  color: var(--color-white); }

.form-group.required .form-control-label::before {
  content: "*";
  color: inherit; }

label {
  font-size: var(--skin-label-font-size);
  color: var(--skin-label-color);
  margin-bottom: 0.5rem; }

::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  color: var(--skin-input-placeholder-color); }

::-moz-placeholder, .form-control::-moz-placeholder {
  color: var(--skin-input-placeholder-color); }

:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  color: var(--skin-input-placeholder-color); }

::-ms-input-placeholder, .form-control::-ms-input-placeholder {
  color: var(--skin-input-placeholder-color); }

::placeholder,
.form-control::placeholder {
  color: var(--skin-input-placeholder-color); }
  .floating-label ::-webkit-input-placeholder, .floating-label
  .form-control::-webkit-input-placeholder {
    color: var(--skin-fl-input-placeholder-color); }
  .floating-label ::-moz-placeholder, .floating-label
  .form-control::-moz-placeholder {
    color: var(--skin-fl-input-placeholder-color); }
  .floating-label :-ms-input-placeholder, .floating-label
  .form-control:-ms-input-placeholder {
    color: var(--skin-fl-input-placeholder-color); }
  .floating-label ::-ms-input-placeholder, .floating-label
  .form-control::-ms-input-placeholder {
    color: var(--skin-fl-input-placeholder-color); }
  .floating-label ::placeholder, .floating-label
  .form-control::placeholder {
    color: var(--skin-fl-input-placeholder-color); }

.form-control {
  border-radius: var(--skin-input-border-radius);
  border: var(--skin-input-border-width) solid var(--skin-input-border-color);
  color: var(--skin-input-text-color);
  background-color: var(--skin-input-bg-color);
  background-position: center right 0.75rem;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-size: var(--skin-input-font-size); }
  .form-control:focus::-webkit-input-placeholder, .form-control:focus-visible::-webkit-input-placeholder {
    color: var(--skin-input-text-color); }
  .form-control:focus::-moz-placeholder, .form-control:focus-visible::-moz-placeholder {
    color: var(--skin-input-text-color); }
  .form-control:focus:-ms-input-placeholder, .form-control:focus-visible:-ms-input-placeholder {
    color: var(--skin-input-text-color); }
  .form-control:focus::-ms-input-placeholder, .form-control:focus-visible::-ms-input-placeholder {
    color: var(--skin-input-text-color); }
  .form-control:focus::placeholder, .form-control:focus-visible::placeholder {
    color: var(--skin-input-text-color); }
  .form-control:focus {
    border-color: var(--skin-input-focus-border-color);
    color: var(--skin-input-hover-text-color);
    outline: 0;
    -webkit-box-shadow: none;
            box-shadow: none; }
  .form-control:focus-visible {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 86, 162, 0.5);
            box-shadow: 0 0 0 0.2rem rgba(0, 86, 162, 0.5); }
  @supports (-webkit-overflow-scrolling: touch) {
    .form-control {
      font-size: 1rem; } }

.floating-label {
  margin-bottom: 0.625rem; }
  .floating-label label {
    font-size: var(--skin-fl-label-font-size);
    top: 0.9375rem;
    left: 0.75rem;
    pointer-events: none; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
    padding: 1.625rem 2.25rem 0.375rem 0.75rem;
    color: var(--skin-fl-input-text-color); }
    .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
      color: var(--skin-fl-label-color); }
  .floating-label select {
    color: var(--color-grey5); }
  .floating-label.value-set input {
    border-color: var(--skin-input-border-color-filled); }
  .floating-label.value-set select {
    padding: 1.625rem 2.25rem 0.375rem 0.75rem;
    color: var(--skin-fl-input-text-color);
    border-color: var(--skin-input-border-color-filled); }
    .floating-label.value-set select + label {
      color: var(--skin-fl-label-color); }
  .floating-label.value-set:not(.custom-state.is-invalid) {
    border-color: var(--color-danger); }
    .floating-label.value-set:not(.custom-state.is-invalid):-moz-read-only {
      border-color: var(--skin-input-border-color);
      background-image: none;
      background-color: var(--skin-input-bg-color); }
    .floating-label.value-set:not(.custom-state.is-invalid):read-only {
      border-color: var(--skin-input-border-color);
      background-image: none;
      background-color: var(--skin-input-bg-color); }

.form-control:disabled,
.form-control[readonly] {
  border-color: var(--skin-input-disabled-border-color);
  background-color: var(--skin-input-disabled-bg-color);
  color: var(--skin-input-disabled-text-color); }
  .form-control:disabled::-webkit-input-placeholder, .form-control[readonly]::-webkit-input-placeholder {
    color: var(--skin-input-disabled-placeholder-color); }
  .form-control:disabled::-moz-placeholder, .form-control[readonly]::-moz-placeholder {
    color: var(--skin-input-disabled-placeholder-color); }
  .form-control:disabled:-ms-input-placeholder, .form-control[readonly]:-ms-input-placeholder {
    color: var(--skin-input-disabled-placeholder-color); }
  .form-control:disabled::-ms-input-placeholder, .form-control[readonly]::-ms-input-placeholder {
    color: var(--skin-input-disabled-placeholder-color); }
  .form-control:disabled::placeholder,
  .form-control[readonly]::placeholder {
    color: var(--skin-input-disabled-placeholder-color); }

.custom-select {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiM3RTdFN0UiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-size: 1.4375rem;
  background-position: center right 0.75rem; }
  .custom-select:focus {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgOUwxMiAxNUwxOCA5IiBzdHJva2U9IiMwMDU2QTIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg=="); }

.custom-checkbox {
  padding-left: 2rem; }
  .custom-checkbox:not(:last-child) {
    margin-bottom: 0.5rem; }
  .custom-checkbox .custom-control-label:before {
    top: 0;
    left: -2rem;
    width: 1.375rem;
    height: 1.375rem;
    border: var(--skin-checkbox-border-width) solid var(--skin-checkbox-border-color);
    background: var(--skin-checkbox-background); }
  .custom-checkbox .custom-control-label:after {
    top: 0;
    left: -2rem;
    width: 1.375rem;
    height: 1.375rem;
    background: no-repeat 50% / 50% 50%; }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
  .custom-checkbox .custom-control-label.selected:before {
    border-color: var(--skin-checkbox-active-border-color);
    background-color: var(--skin-checkbox-active-background); }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after,
  .custom-checkbox .custom-control-label.selected:after {
    background-image: var(--skin-check);
    background-repeat: no-repeat; }
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label,
  .custom-checkbox .custom-control-label.disabled {
    color: var(--skin-checkbox-disabled-label-color); }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-checkbox .custom-control-label.disabled:before {
      border-color: var(--skin-checkbox-disabled-border-color);
      background-color: var(--skin-checkbox-disabled-background-color); }

.custom-radio {
  padding-left: 2rem; }
  .custom-radio .radio-line:not(:last-child) {
    margin-bottom: 0.5rem; }
  .custom-radio .custom-control-label:before {
    top: 0;
    left: -2rem;
    width: 1.375rem;
    height: 1.375rem;
    border: var(--skin-radio-border-width) solid var(--skin-radio-border-color);
    background-color: var(--skin-radio-background); }
  .custom-radio .custom-control-label:after {
    top: 0;
    left: -2rem;
    width: 1.375rem;
    height: 1.375rem;
    background: no-repeat 50% / 50% 50%; }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:before,
  .custom-radio .custom-control-label.selected:before {
    border: var(--skin-radio-border-width) solid var(--skin-radio-active-border-color);
    background-color: var(--skin-radio-active-background); }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:after,
  .custom-radio .custom-control-label.selected:after {
    background-image: var(--skin-dot);
    background-size: var(--skin-radio-dot-size); }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label,
  .custom-radio .custom-control-label.disabled {
    color: var(--skin-radio-disabled-label-color); }
    .custom-radio .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-radio .custom-control-label.disabled:before {
      border-color: var(--skin-radio-disabled-border-color);
      background-color: var(--skin-radio-disabled-background-color); }

.custom-switch {
  padding-left: 3.75rem; }
  .custom-switch .switch-line:not(:last-child) {
    margin-bottom: 0.75rem; }
  .custom-switch .custom-control-label:before {
    border: var(--skin-switch-border-width) solid var(--skin-switch-border-color);
    background-color: var(--skin-switch-background);
    height: 1.5rem;
    width: 2.875rem;
    border-radius: 0.75rem;
    top: 0;
    left: -3.75rem; }
  .custom-switch .custom-control-label:after {
    background-color: var(--skin-switch-btn-color);
    height: 1.125rem;
    width: 1.125rem;
    top: 0.1875rem;
    left: -3.5625rem; }
  .custom-switch .custom-control-label .switch-text-label {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    display: block;
    position: absolute;
    z-index: 5;
    color: var(--color-blue);
    font-size: 0.75rem;
    top: 0.125rem;
    left: -2.375rem; }
    .custom-switch .custom-control-label .switch-text-label:after {
      display: block;
      content: 'Off'; }
  .custom-switch .custom-control-input:checked ~ .custom-control-label:before {
    border-color: var(--skin-switch-active-border-color);
    background-color: var(--skin-switch-active-background); }
  .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    background-color: var(--skin-switch-active-btn-color);
    left: -3.625rem;
    -webkit-transform: translateX(22px);
            transform: translateX(22px); }
  .custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-label {
    color: var(--color-white);
    left: -3.5rem; }
    .custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-label:after {
      content: 'On'; }
  .custom-switch .custom-control-input:disabled ~ .custom-control-label {
    color: var(--skin-switch-disabled-label-color); }
    .custom-switch .custom-control-input:disabled ~ .custom-control-label:before {
      border-color: var(--skin-switch-disabled-border-color);
      background-color: var(--skin-switch-disabled-background-color); }
    .custom-switch .custom-control-input:disabled ~ .custom-control-label:after {
      background-color: var(--skin-switch-disabled-btn-color); }
    .custom-switch .custom-control-input:disabled ~ .custom-control-label .switch-text-label {
      color: var(--skin-switch-disabled-label-color); }

.form-group > span:not(.info-icon),
.form-group > span:not(.bday-info),
.form-group > span:not(.bday-info-text) {
  font-size: var(--skin-input-helper-font-size);
  color: var(--skin-input-helper-color); }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--color-danger);
  background-color: #FFF5F7;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHN0cm9rZS13aWR0aD0iMS41IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMC4wNDI5IDIxSDMuOTU3MDVDMi40MTkwMiAyMSAxLjQ1NjU4IDE5LjMzNjQgMi4yMjMyNCAxOC4wMDMxTDEwLjI2NjIgNC4wMTUzM0MxMS4wMzUyIDIuNjc3OTIgMTIuOTY0OCAyLjY3NzkxIDEzLjczMzggNC4wMTUzMkwyMS43NzY4IDE4LjAwMzFDMjIuNTQzNCAxOS4zMzY0IDIxLjU4MSAyMSAyMC4wNDI5IDIxWiIgc3Ryb2tlPSIjRDAwQTJEIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTEyIDlWMTMiIHN0cm9rZT0iI0QwMEEyRCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMiAxNy4wMUwxMi4wMSAxNi45OTg5IiBzdHJva2U9IiNEMDBBMkQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-size: 1.4375rem;
  background-position: center right 0.75rem; }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    border-color: var(--color-danger);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25);
            box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  border-color: var(--color-danger);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjIuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0Ny40IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0Ny40IDI0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6bm9uZTtzdHJva2U6I0QwMEEyRDtzdHJva2Utd2lkdGg6MS41O3N0cm9rZS1saW5lY2FwOnJvdW5kO30KCS5zdDF7ZmlsbDpub25lO3N0cm9rZTojRDAwQTJEO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30KCS5zdDJ7ZmlsbDpub25lO3N0cm9rZTojMDA1NkEyO3N0cm9rZS13aWR0aDoxLjU7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTIwLDIxSDRjLTEuNSwwLTIuNS0xLjctMS43LTNsOC0xNEMxMSwyLjcsMTMsMi43LDEzLjcsNGw4LDE0QzIyLjUsMTkuMywyMS42LDIxLDIwLDIxeiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTIsOXY0Ii8+CjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0xMiwxN0wxMiwxNyIvPgo8cGF0aCBjbGFzcz0ic3QyIiBkPSJNMjkuNywxMGw2LDZsNi02Ii8+Cjwvc3ZnPgo=");
  background-size: 3rem;
  background-position: center right 0.75rem; }

.invalid-feedback {
  color: var(--color-danger);
  font-size: 0.75rem; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

.form-group .tooltip-show:not(.show-mobile) {
  display: none; }
  @media (min-width: 769px) {
    .form-group .tooltip-show:not(.show-mobile) {
      display: inline-block; } }
  .form-group .tooltip-show:not(.show-mobile).show-mobile {
    display: inline-block; }

.card,
.card-header {
  background-color: var(--skin-background-color);
  font-family: var(--skin-body-font);
  color: var(--skin-main-text-color);
  border-color: var(--color-grey3); }

.card {
  border-radius: 0.25rem; }

.main-menu {
  background-color: transparent; }

.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a,
.dropdown-menu,
.dropdown-menu a {
  color: var(--skin-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    .navbar,
    .navbar .nav-item,
    .navbar .nav-item a,
    .navbar .nav-item.dropdown,
    .navbar .nav-item.dropdown a,
    .dropdown-menu,
    .dropdown-menu a {
      color: var(--skin-menu-topnav-links-color); } }

.navbar .nav-item.dropdown .dropdown-menu {
  background-color: var(--skin-menu-subnav-bg-color); }
  .navbar .nav-item.dropdown .dropdown-menu .show {
    background-color: var(--skin-menu-subnav-bg-color); }

.navbar .nav-item a.nav-link {
  background-color: transparent;
  color: var(--skin-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    .navbar .nav-item a.nav-link {
      padding: 1rem;
      text-transform: none;
      color: var(--skin-menu-topnav-links-color);
      border-bottom: 1px solid transparent;
      border-top: 1px solid transparent; } }

@media (min-width: 992px) and (max-width: 1469.98px) {
  .navbar .nav-item a.nav-link {
    padding-left: calc(16 * ((100vw - 1082px) / 560));
    padding-right: calc(16 * ((100vw - 1082px) / 560));
    margin: 0px 4px;
    font-size: calc(12px + (14 - 12) * ((60vw - 200px) / (1042 - 200))); } }

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-menu-subnav-links-color); }

.navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-menu-subnav-bg-color); }
  .navbar .nav-item.dropdown .dropdown-menu.show a,
  .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-menu-subnav-links-color); }

.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link:focus,
.navbar .nav-item.show .nav-link {
  background-color: transparent;
  color: var(--skin-menu-topnav-links-color-hover) !important; }
  @media (min-width: 992px) {
    .navbar .nav-item .nav-link:hover,
    .navbar .nav-item .nav-link:focus,
    .navbar .nav-item.show .nav-link {
      border-bottom: 1px solid var(--skin-menu-nav-links-indicator-hover); } }

@media (min-width: 992px) {
  .navbar .cat-level-2 > .dropdown-link {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--skin-menu-subnav-links-color);
    font-size: 0.875rem; } }

body.bearcave .navbar,
body.bearcave .navbar .nav-item,
body.bearcave .navbar .nav-item a,
body.bearcave .navbar .nav-item.dropdown,
body.bearcave .navbar .nav-item.dropdown a,
body.bearcave .dropdown-menu,
body.bearcave .dropdown-menu a {
  color: var(--skin-bc-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    body.bearcave .navbar,
    body.bearcave .navbar .nav-item,
    body.bearcave .navbar .nav-item a,
    body.bearcave .navbar .nav-item.dropdown,
    body.bearcave .navbar .nav-item.dropdown a,
    body.bearcave .dropdown-menu,
    body.bearcave .dropdown-menu a {
      color: var(--skin-bc-menu-topnav-links-color); } }

body.bearcave .navbar .nav-item.dropdown .dropdown-menu {
  background-color: var(--skin-bc-menu-subnav-bg-color); }
  body.bearcave .navbar .nav-item.dropdown .dropdown-menu .show {
    background-color: var(--skin-bc-menu-subnav-bg-color); }

body.bearcave .navbar .nav-item a.nav-link {
  color: var(--skin-bc-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    body.bearcave .navbar .nav-item a.nav-link {
      color: var(--skin-bc-menu-topnav-links-color); } }

body.bearcave .navbar .nav-item.dropdown,
body.bearcave .navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-bc-menu-subnav-links-color); }

body.bearcave .navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-bc-menu-subnav-bg-color); }
  body.bearcave .navbar .nav-item.dropdown .dropdown-menu.show a,
  body.bearcave .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-bc-menu-subnav-links-color); }

body.bearcave .navbar .nav-item .nav-link:hover,
body.bearcave .navbar .nav-item .nav-link:focus,
body.bearcave .navbar .nav-item.show .nav-link {
  color: var(--skin-bc-menu-topnav-links-color-hover) !important; }
  @media (min-width: 992px) {
    body.bearcave .navbar .nav-item .nav-link:hover,
    body.bearcave .navbar .nav-item .nav-link:focus,
    body.bearcave .navbar .nav-item.show .nav-link {
      border-bottom-color: var(--skin-bc-menu-nav-links-indicator-hover); } }

@media (min-width: 992px) {
  body.bearcave .navbar .cat-level-2 > .dropdown-link {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    color: var(--skin-bc-menu-subnav-links-color); } }

body.heartbox .navbar,
body.heartbox .navbar .nav-item,
body.heartbox .navbar .nav-item a,
body.heartbox .navbar .nav-item.dropdown,
body.heartbox .navbar .nav-item.dropdown a,
body.heartbox .dropdown-menu,
body.heartbox .dropdown-menu a {
  color: var(--skin-hb-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    body.heartbox .navbar,
    body.heartbox .navbar .nav-item,
    body.heartbox .navbar .nav-item a,
    body.heartbox .navbar .nav-item.dropdown,
    body.heartbox .navbar .nav-item.dropdown a,
    body.heartbox .dropdown-menu,
    body.heartbox .dropdown-menu a {
      color: var(--skin-hb-menu-topnav-links-color); } }

body.heartbox .navbar .nav-item.dropdown .dropdown-menu {
  background-color: var(--skin-hb-menu-subnav-bg-color); }
  body.heartbox .navbar .nav-item.dropdown .dropdown-menu .show {
    background-color: var(--skin-hb-menu-subnav-bg-color); }

body.heartbox .navbar .nav-item a.nav-link {
  color: var(--skin-hb-menu-nav-mobile-links-color); }
  @media (min-width: 992px) {
    body.heartbox .navbar .nav-item a.nav-link {
      color: var(--skin-hb-menu-topnav-links-color); } }

body.heartbox .navbar .nav-item.dropdown,
body.heartbox .navbar .nav-item.dropdown .dropdown-menu.show {
  color: var(--skin-hb-menu-subnav-links-color); }

body.heartbox .navbar .nav-item.dropdown .dropdown-menu.show {
  background-color: var(--skin-hb-menu-subnav-bg-color); }
  body.heartbox .navbar .nav-item.dropdown .dropdown-menu.show a,
  body.heartbox .navbar .nav-item.dropdown .dropdown-menu.show .nav-item {
    color: var(--skin-hb-menu-subnav-links-color); }

body.heartbox .navbar .nav-item .nav-link:hover,
body.heartbox .navbar .nav-item .nav-link:focus,
body.heartbox .navbar .nav-item.show .nav-link {
  color: var(--skin-hb-menu-topnav-links-color-hover) !important; }
  @media (min-width: 992px) {
    body.heartbox .navbar .nav-item .nav-link:hover,
    body.heartbox .navbar .nav-item .nav-link:focus,
    body.heartbox .navbar .nav-item.show .nav-link {
      border-bottom-color: var(--skin-hb-menu-nav-links-indicator-hover); } }

@media (min-width: 992px) {
  body.heartbox .navbar .cat-level-2 > .dropdown-link {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    color: var(--skin-hb-menu-subnav-links-color); } }

.refinements .refinement {
  border: 0; }

.refinements ul li button {
  color: var(--color-blue-dark); }

.refinements ul li.size-attribute button {
  border: 1px solid var(--color-grey5);
  background-color: var(--color-white); }
  .refinements ul li.size-attribute button.selected {
    border: 1px solid var(--color-blue);
    background-color: var(--color-blue);
    color: var(--color-white); }

.alert.no-product-error {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  text-align: center; }

.compare-bar-wrapper {
  background-color: var(--color-grey1);
  border-top: 1px solid var(--color-hr-border-color); }
  .compare-bar-wrapper .slot {
    background-color: var(--color-white); }
  .compare-bar-wrapper .toggle-compare {
    background-color: var(--color-grey1);
    border: 1px solid var(--color-hr-border-color);
    border-bottom: 0; }
